:root {
  /* Teal */
  --teal-00: #001813;
  --teal-05: #002b23;
  --teal-06: #08443a; /* newsletter button and user stories */
  --teal-07: #1a5e52;
  --teal-10: #559086; /* text underline on homepage */
  --teal-20: #82aea7;
  --teal-30: #b5d1cc;
  --teal-35: #bdd8da; /* navbar */
  --teal-40: #dee8e9;
  --teal-50: #e9eff1; /* link highlight */

  /* Grey */
  --gray-10: #4f4e4e;
  --gray-30: #959393;
  --gray-35: #a2a1a1; /* Trustpilot button active */
  --gray-40: #c1c1c1; /* Trustpilot button */
  --gray-45: #dbdbdb; /* btn active */
  --gray-47: #e5e5e5; /* btn */
  --gray-50: #efefef; /* footer */
  --gray-52: #f4f4f4;
  --gray-55: #f9f9f9; /* product description */

  /* Navy */
  --blue-00: #00080e;
  --blue-01: #011220;
  --blue-10: #051f36; /* slide, link text */
  --blue-12: #072845; /* nav banner */
  --blue-15: #13334f;
  --blue-20: #294c6b; /* alternate link text */
  --blue-25: #536fba /* resources menu item */;
  --blue-30: #5f7496;

  /* Coral */
  --coral-20: #fe7f78;
  --coral-30: #fe8f8b;
  --coral-50: #f1b1af;
}

/* LIST */
ul {
  list-style: none;
  margin-left: 0;
  padding-left: 0em;
  list-style-position: outside;
}

ul li {
  padding-left: 2em;
  text-indent: -2em;
}

ul li:before {
  content: "—";
  padding-right: 1.16em;
}

ol {
  margin-left: 1em;
  padding-left: inherit;
  list-style-position: outside;
}

ol li:before {
  padding-right: 0em;
}

ol li {
  padding-left: 1em;
  text-indent: 0em;
}

.blog li > ul,
.blog li > ol {
  margin-bottom: 0px;
}

/* INPUT */
input[type="text"] {
  background: white;
  border: 2px solid var(--gray-47);
  border-radius: 3px;
  font-family: inherit;
  padding: 2px 6px 2px 6px;
}

input[type="text"]:focus {
  background-color: var(--teal-40);
  border: 2px solid var(--gray-47) !important;
}

input[type="number"] {
  background: white;
  border: 2px solid var(--gray-47) !important;
  border-radius: 3px;
  font-family: inherit;
  padding: 2px 6px 2px 6px;
}

input[type="number"]:focus {
  background-color: var(--teal-40);
  border: 2px solid var(--gray-47) !important;
}

.field:focus {
  outline: 1px solid #228cfa;
  background: white;
}

.field:valid,
.field:active,
.field:visited {
  border: 2px solid var(--gray-47) !important;
  background: white !important;
  background-color: white !important;
}

.checkout-form-input {
  height: 44.4px;
  padding: 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.4px;
  width: 100%;
}

/* LAYOUTS */
body {
  margin: 0;
  font-family: Roboto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.title {
  font-family: Raleway;
  font-feature-settings: "lnum";
}

#root {
  background: var(--gray-55);
}

@media (min-width: 1024px) {
  #root {
    background: #fff;
  }
}

.page {
  min-height: 88vh;
}

.product-banner {
  background: var(--teal-50);
  display: flex;
}

.rounded {
  border-radius: 30px;
}

.container {
  max-width: 900px;
  margin: 0 auto;
  padding: 0 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@media (min-width: 1024px) {
  .testimonial-text {
    width: 95%;
  }
}

.testimonial-img {
  border-radius: 50%;
  height: 60px;
  width: 60px;
  margin-left: 20px;
}

.buttons {
  width: 100%;
}

.buttons button {
  margin-right: 20px;
  margin-bottom: 10px;
}

button:disabled {
  cursor: not-allowed;
}

h1 {
  font-family: Raleway;
  font-feature-settings: "lnum";
  font-size: 32px;
  font-weight: 600;
  color: #4a4a4a;
}

.blog h2 {
  font-family: Raleway;
  font-feature-settings: "lnum";
  font-size: 1.2em;
  margin-top: 3em;
  margin-bottom: 0.2em;
  font-weight: 600;
  max-width: 1000px;
}

.blog h3 {
  font-size: 1.1em;
  margin-bottom: 1em;
  max-width: 1000px;
}

.blog h5 {
  font-size: 0.9em;
  font-style: italic;
  margin-top: -4px;
  margin-bottom: 1em;
  max-width: 1000px;
}

.blog h6 {
  font-size: 1.05em;
  font-weight: 900;
  max-width: 1000px;
  color: #4f4e4e;
}

.blog figure {
  margin: 3em 0;
}

.blog p {
  margin-bottom: 1em;
  max-width: 1000px;
}

.blog .h7 {
  margin-bottom: 0em;
  font-weight: 900;
  max-width: 1000px;
  color: #4f4e4e;
}

.blog .h8 {
  margin-bottom: 0em;
  font-style: italic;
}

.blog p:has(+ ul),
p:has(+ ol) {
  margin-bottom: 0 !important;
}

.blog {
  margin-bottom: 0;
}

.blog ul,
ol {
  margin-bottom: 1em;
}

.blog li {
  max-width: 1000px;
}

.blog hr {
  max-width: 1000px;
}

.blog blockquote {
  margin-left: 40px;
  max-width: 960px;
}

.table p {
  margin-bottom: 0;
}

@media (min-width: 640px) {
  .blog figure img {
    box-shadow: 0px 3px 7px 3px rgba(0, 0, 0, 0.07);
  }
}

@media (min-width: 768px) {
  .blog blockquote {
    margin-top: 0.5em;
    font-size: 1.1em;
    margin-bottom: 0.5em;
  }
}

.blog figcaption {
  display: block;
  font-size: 0.8em;
  margin-bottom: 1.2em;
}

@media (min-width: 1024px) {
  .blog figure {
    max-width: 1000px;
    text-align: center;
  }

  .blog img {
    max-width: 800px;
    max-height: 530px;
  }

  .blog figcaption {
    display: block;
    font-size: 0.9em;
    margin-bottom: 1.2em;
  }
}

.box-shadow {
  box-shadow: 0px 3px 7px 3px rgba(0, 0, 0, 0.07);
}

.oath p {
  margin-bottom: 1em;
}

.extended-description {
  margin-top: 10px;
}

.extended-description p,
ul {
  margin-bottom: 1em;
}

.extended-description.faq p {
  margin-bottom: 0em;
}

@media (min-width: 800px) {
  .extended-description {
    margin-right: 60px;
  }
}

/* HOMEPAGE */
.homepage li:before {
  content: "";
  padding-right: 1em;
}

.teal-box {
  background: var(--teal-40);
  margin-top: 20px;
  padding: 20px 20px 22px 20px;
}

.teal-box.homepage {
  margin: auto;
}

#mission p {
  font-size: 1.2em;
  font-weight: 200;
  text-align: center;
  max-width: 1000px;
  margin: 1em auto 1em auto;
  text-wrap: balance;
}

#product {
  border-bottom: 2px solid var(--teal-10);
}

#product:hover {
  border: 3px solid var(--coral-50);
  border-bottom: 2px solid var(--teal-10);
  background: var(--coral-50);
}

.teal-box.blog {
  max-width: 1000px;
}

@media (min-width: 640px) {
  .homepage-box {
    height: 45%;
    width: 70%;
  }

  .teal-box.homepage {
    padding: 30px 40px 32px 40px;
  }

  .teal-box.product-layout {
    margin: 20px;
  }

  .teal-box.blog {
    margin: 20px;
  }
}

figcaption {
  display: none;
}

@media (min-width: 768px) {
  figcaption {
    display: block;
    font-size: 0.8em;
  }
}

@media (min-width: 1024px) {
  .homepage-box {
    min-height: 45%;
    width: 70%;
  }

  figcaption {
    display: block;
    font-size: 1em;
  }

  .homepage {
    width: 85%;
  }

  .product-info .teal-box {
    margin: 40px 0;
  }

  .teal-box.blog {
    margin: 40px 0;
    padding: 30px 40px 32px 40px;
  }
}

.zoom-on-hover,
.zoom-on-hover-subtle {
  transition: transform 0.3s ease; /* Smooth transition effect */
}

.zoom-on-hover:hover {
  transform: scale(1.05); /* Zoom in by 20% on hover */
}

.zoom-on-hover-subtle:hover {
  transform: scale(1.03); /* Zoom in by 20% on hover */
}

.right-on-hover {
  transition: transform 0.3s ease; /* Smooth transition effect */
}

.right-on-hover:hover {
  transform: translateX(10px); /* Zoom in by 20% on hover */
}

.angle {
  -webkit-transform: rotate(-7.5deg);
  -ms-transform: rotate(-7.5deg);
  transform: rotate(-7.5deg);
}

.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

@keyframes animate-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Splat In */
.animate.splat {
  animation-name: animate-splat;
}

@keyframes animate-splat {
  0% {
    opacity: 0;
    transform: scale(0, 0) rotate(-20deg) translate(0, -30px);
  }
  70% {
    opacity: 1;
    transform: scale(1.1, 1.1) rotate(-15deg);
  }
  85% {
    opacity: 1;
    transform: scale(1.1, 1.1) rotate(-10deg) translate(0, -10px);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1) rotate(-5) translate(0, 0);
  }
}

.animate.pop {
  animation-duration: 0.5s;
  animation-name: animate-pop;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
}

@keyframes animate-pop {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

.delay-1 {
  animation-delay: 1s;
}

/* homepage video */
.video-box {
  margin-bottom: 40px;
  padding-bottom: 50%;
  overflow: hidden;
  position: relative;
  max-width: 780px;
  margin: auto;
  max-height: 440px;
}

#homepage {
  padding-bottom: 56.25%;
}

.video-box iframe {
  overflow: hidden;
  align-self: center;
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 780px;
  max-height: 440px;
}

.video-description-box {
  margin-top: 40px;
  background: white;
}

@media (min-width: 640px) {
  .video-description-box {
    padding: 40px;
    border: 5px solid var(--teal-20);
  }
}

@media (min-width: 1024px) {
  .video-box {
    padding-bottom: 440px;
    margin-bottom: 0px;
  }

  #homepage {
    padding-bottom: 440px;
  }
}

@media (min-width: 1600px) {
  .video-container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 40px;
  }

  #homepage {
    position: static;
    padding-bottom: 0;
    margin: 0;
  }

  #homepage iframe {
    position: static;
  }

  .video-description-box {
    margin-top: 0px;
    padding: 40px;
  }
}

/* LINKS */
p a,
h2 a,
h3 a,
h4 a,
h5 a,
figcaption a,
li a,
.fake-link {
  color: var(--blue-10);
  text-decoration: none;
  font-weight: 700;
  padding-bottom: -1px;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-bottom-color: var(--teal-40);
}

p a:hover,
h2 a:hover,
h3 a:hover,
figcaption a:hover,
li a:hover,
.fake-link:hover {
  border: 3px solid var(--teal-50);
  margin: -3px;
  background: var(--teal-50);
}

nav li a {
  color: inherit;
  text-decoration: none;
  border-bottom: none;
}

nav li a:hover {
  border: none;
  margin: inherit;
  background: none;
}

.fake-link,
.fake-link-1 {
  cursor: pointer;
}

.fake-link-1 {
  font-style: italic;
  padding-bottom: -1px;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-bottom-color: var(--teal-40);
}

#sale a:hover {
  border: 3px solid var(--blue-12);
  background: none;
  font-style: italic;
  border-bottom: 2px solid var(--blue-12);
}

.tandc a {
  color: inherit;
}

.tandc a:hover {
  font-style: italic;
}

.tandc a:active {
  font-style: none;
}

/* BUTTONS */
.btn {
  border-radius: 3px;
  display: inline-block;
  cursor: pointer;
  font-size: 0.9em;
  font-family: inherit;
  padding: 10px 20px;
  border: 0;
  transition: background-color 200ms, color 200ms;
  font-family: Raleway;
  font-feature-settings: "lnum";
}

.btn-default {
  background-color: var(--gray-47);
}

.btn-default:hover {
  background-color: var(--gray-45);
  font-weight: 600;
  text-decoration: none;
}

input[type="submit"] {
  display: inline-block;
  cursor: pointer;
  font-family: inherit;
  padding: 4px 20px 4px 20px;
  border: 0;
  transition: background-color 200ms, color 200ms;
  background-color: var(--gray-47);
}

input[type="submit"]:hover {
  background-color: var(--gray-45);
  font-weight: 600;
}

.btn-default:active {
  background-color: var(--gray-45);
  font-weight: 600;
}

.btn-accent {
  background-color: var(--coral-30);
  text-decoration: none;
}

.btn-accent:hover {
  background-color: var(--coral-20);
  font-weight: 600;
}

.btn-accent:active {
  background-color: var(--coral-20);
  font-weight: 600;
}

.btn-fade {
  background-color: var(--coral-50);
  text-decoration: none;
}

.btn-fade:hover {
  background-color: var(--coral-30);
  font-weight: 600;
}

.btn-fade:active {
  background-color: var(--coral-30);
  font-weight: 600;
}

.btn-fade.active {
  background-color: var(--coral-30);
  font-weight: 600;
}

#btn-purchase {
  width: 100%;
  padding: 16px 20px;
  margin-top: 14px;
  font-weight: 900;
  font-size: 1.05em;
}

.btn-trustpilot {
  background-color: var(--gray-40);
  font-weight: 400 !important;
}

.btn-trustpilot:hover {
  background-color: var(--gray-35);
  font-weight: 600 !important;
}

.btn-link {
  font-size: 0.9em;
  color: white;
  background-color: var(--blue-15);
  font-weight: 400 !important;
}

.btn-link:hover {
  background-color: var(--blue-12);
  font-weight: 600 !important;
}

.btn-trustpilot:active {
  background-color: var(--gray-35);
  font-weight: 600;
}

.btn-nav {
  background-color: var(--teal-06);
  text-decoration: none;
  font-weight: 600;
  color: white;
  padding: 12px 22px;
  font-size: 1.02em;
}

.btn-nav:hover {
  background-color: var(--teal-05);
  font-weight: 900;
}

.btn-nav:active {
  background-color: var(--teal-05);
  font-weight: 900;
}

/* NAVBAR */

.resources-menu-item {
  line-height: 1.5 !important;
}

.resources-menu-item:hover {
  background-color: var(--gray-52);
  color: var(--blue-25) !important;
}

.navbar {
  background-color: var(--teal-35);
  padding: 10px;
  box-shadow: 0px 3px 7px 1px rgba(0, 0, 0, 0.07), 0px -3px 7px 1px rgba(0, 0, 0, 0.07);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#header-img {
  display: none;
}

.nav-brand {
  font-family: Raleway;
  font-feature-settings: "lnum";
  font-size: 1.2em;
  font-weight: 700;
  text-decoration: none;
  padding-left: 0.8em;
  color: var(--blue-01);
  margin: auto;
}

.navbar li {
  display: none;
}

.navbar-menu {
  text-align: right !important;
  position: absolute;
  width: 30vw;
  right: 10px;
  z-index: 2;
  font-size: 0.8em;
  padding: 0.2rem 0.2rem;
  line-height: 0;
}

.navbar-item {
  text-align: right;
}

.navbar-burger {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.navbar-burger:active {
  background: none;
}

#nav-item-burger {
  display: none;
}

@media (min-width: 640px) {
  #header-img {
    border-radius: 50%;
    height: 60px;
    min-width: 60px;
    align-self: center;
    display: inline-block;
    object-fit: cover;
    background-size: cover;
  }

  .nav-brand {
    padding-left: 20px;
  }

  .navbar ul {
    list-style-type: none;
    overflow: hidden;
    padding-right: 1vw;
    text-align: center;
  }

  .navbar li {
    display: inline-block;
    padding-left: 0;
    text-indent: 0;
  }

  .navbar li:before {
    content: "";
    padding-right: 0;
  }

  .nav-item a {
    font-size: 0.8em;
    padding: 13px 5px;
    min-width: 50px;
    text-decoration: none;
    font-weight: normal;
    color: var(--blue-01);
    display: inline-block;
    text-align: center;
  }

  .nav-item a:hover {
    text-decoration: none;
    color: var(--blue-20);
    border: none;
    margin: inherit;
    background: none;
  }

  .nav-item a.active {
    font-weight: bold;
    margin: inherit;
  }

  #navbarBasic {
    display: none;
  }
}

@media (min-width: 750px) {
  #nav-burger {
    display: none;
  }
}

@media (min-width: 1000px) {
  .nav-item a {
    font-size: 0.95em;
    padding: 13px 20px;
  }

  .nav-item {
    width: 115px;
  }

  #nav-item-burger {
    display: block;
  }
}

/* FOOTER */
.footer-container {
  display: flex;
  height: 12vh;
  min-height: 100px;
  max-height: 140px;
  background-color: var(--gray-50);
  color: var(--gray-30);
  font-size: 0.8em;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  bottom: 0;
  width: 100%;
}

.footer-container img {
  width: 30px;
  margin: 8px 8px 2px 8px;
}

/* PRODUCT */
.product-layout {
  padding-top: 20px;
  background: var(--gray-55);
  max-width: 2000px;
}

.product-title {
  font-size: 24px;
  padding-left: 20px;
  padding-right: 20px;
}

#product-img {
  height: 300px;
  width: 300px;
  box-shadow: 0px 3px 7px 3px rgba(0, 0, 0, 0.07);
  margin-bottom: 4px;
  object-fit: cover;
}

#product-left-side {
  max-width: 300px;
  margin: 10px auto 20px auto;
}

.box-title {
  margin-bottom: 8px;
}

.gray-box {
  background: var(--gray-50);
  margin-bottom: 20px;
  padding: 20px;
}

.white-box {
  background: var(--gray-55);
  margin: 40px 0 40px 0;
  padding: 20px 20px 22px 20px;
}

.white-outline-box {
  background: var(--gray-55);
  margin: 40px 0 40px 0;
  padding: 20px;
  border: 5px solid var(--teal-20);
}

.image-display-tri {
  display: flex !important;
  justify-content: space-evenly;
  gap: 20px;
  max-width: 2000px;
}

.image-display-tri img {
  display: inline-block;
  max-width: 25vw;
  height: auto;
  text-align: center;
}

#user-stories img {
  display: inline-block;
  min-width: 85vw;
  max-width: 20vw;
  max-height: 120px;
  text-align: center;
  object-position: center;
}

#user-stories .wrap:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  min-width: 85vw;
  width: 100%;
  background: var(--teal-06);
  opacity: 0.4;
  z-index: 2;
  object-position: center;
  max-width: calc(1900px / 3);
}

#user-stories .wrap {
  position: relative;
  display: flex;
  align-items: center;
  object-position: center;
}

.visual-description-item {
  margin-bottom: 2em;
}

.visual-description-item:last-child {
  margin-bottom: -1em;
}

.product-testimonial-card-simplified a {
  color: white;
  border-bottom-color: white;
}

.wrap p {
  position: absolute;
  font-family: Raleway;
  font-feature-settings: "lnum";
  font-size: 1.1em;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--gray-55);
  font-weight: 900;
  z-index: 3;
  text-align: center;
  text-shadow: -0.35px -0.35px 0 var(--gray-55), 0.35px -0.35px 0 var(--gray-55), 0.35px 0.35px 0 var(--gray-55),
    -0.35px 0.35px 0 var(--gray-55);
  letter-spacing: 0.12em;
  line-height: 1.1;
}

@media (min-width: 960px) {
  #user-stories img {
    min-width: 26vw;
    max-height: 200px;
  }
}

@media (min-width: 1200px) {
  .wrap p {
    font-size: 1.6em;
  }

  .footer-container img {
    margin: 4px 4px 2px 4px;
  }
}

@media (min-width: 2000px) {
  .image-display-tri img {
    max-width: calc(1900px / 3);
  }

  .image-display-tri figure {
    max-width: 500px;
  }

  .image-display-tri figure img {
    max-width: 500px;
  }

  #user-stories img {
    min-width: 0px;
    max-width: calc(1900px / 3) !important;
    width: calc(1900px / 3) !important;
    text-align: center;
    overflow: hidden;
  }
}

/* .gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
} */

.gallery figure {
  width: 200px;
  height: 200px;
  background-color: white;
}

.gallery img {
  width: 200px;
  height: 200px;
  object-fit: contain;
}

.float-right {
  float: right;
}

#product-testimonials {
  margin-bottom: 20px;
}

.product-info {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .product-layout {
    margin: 20px;
    padding: 0;
  }
  .product-title {
    padding: 0;
  }

  #product-left-side {
    margin: 0;
  }
  #product-img {
    margin-bottom: 10px;
  }

  .product-info {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 300px 1fr;
    margin-bottom: 40px;
  }

  #product-description {
    margin: 0;
    padding: 20px;
    width: 100%;
    height: 100%;
  }

  .gray-box {
    width: auto;
    height: 100%;
    margin: 20px;
  }

  #product-testimonials {
    grid-column: 1 / 3;
    margin: 0;
    margin-bottom: 20px;
    height: 100%;
  }

  #product-left-side {
    max-width: none;
    margin: none;
  }

  #product-description {
    height: 100%;
  }

  .center-text-box {
    width: 95%;
    margin: auto;
    max-width: 1600px;
  }

  .float-left {
    float: left;
  }

  .two-col {
    column-count: 2;
    column-gap: 40px;
  }

  .t-shirt {
    background: #f9f9f9;
    box-shadow: 0px 3px 7px 3px rgba(0, 0, 0, 0.07);
    height: "auto";
  }
}

@media (min-width: 1024px) {
  .product-layout {
    margin: 40px 80px 40px 80px;
    padding: 20px;
    border: 5px solid var(--gray-55);
    background: var(--gray-55);
    box-shadow: 0px 2px 7px 7px rgba(0, 0, 0, 0.07);
  }

  .sevenths {
    grid-template-columns: 300px 4fr 3fr;
  }

  .image-display-tri img {
    box-shadow: 0px 3px 7px 3px rgba(0, 0, 0, 0.07);
  }

  .gray-box {
    margin: 40px 0 40px 0;
    padding: 20px;
  }

  .box-title {
    text-align: center;
    margin-bottom: 12px;
  }

  .three-col {
    column-count: 3;
    padding-bottom: 20px;
    column-gap: 40px;
  }
}

.home-layout {
  padding: 20px;
  border: 5px solid var(--gray-55);
  background: var(--gray-55);
  max-width: 2000px;
  margin: auto;
}

.product-catalog {
  margin-top: 50px;
  margin-bottom: 60px;
}

.product-catalog .content {
  height: 6em;
}

.product-catalog .card-content {
  margin: 10px 0px -5px 0px;
}

.extended-description-container {
  grid-column: 1 / -1;
}

@media (min-width: 640px) {
  .product-catalog {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(auto-fill, auto);
    grid-column-gap: 20px;
    grid-row-gap: 40px;
  }

  .product-catalog .content {
    height: 12em;
  }
}

@media (min-width: 1024px) {
  .product-catalog {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;
  }

  .product-catalog .content {
    height: 13em;
  }

  .home-layout {
    margin: 40px 80px;
    box-shadow: 0px 2px 7px 7px rgba(0, 0, 0, 0.07);
  }

  .product-banner {
    margin: 20px 76px;
  }
}

@media (min-width: 1200px) {
  .product-catalog .content {
    height: 11em;
  }
}

@media (min-width: 1400px) {
  .product-catalog {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 40px;
    justify-content: center;
  }
}

@media (min-width: 2000px) {
  .product-layout {
    margin: 40px auto;
  }

  .home-layout {
    margin: 40px auto;
  }

  .product-banner {
    margin: 20px 0px;
  }
}

@media (min-width: 1300px) {
  .thirds {
    grid-template-columns: 300px 2fr 1fr;
  }

  .product-info.thirds {
    margin-bottom: 40px;
  }

  #product-testimonials {
    grid-column: 3 / 4;
  }

  .extended-description-container {
    grid-column: 2 / 4;
  }
}

/* CARD */
.card {
  margin-bottom: 2vh;
  max-width: 2000px;
}

.card-image {
  overflow: hidden;
  border: 1px solid var(--gray-50);
  height: 12rem;
  border-radius: 0.25rem 0.25rem 0 0;
}

.card-image img {
  margin-top: -3rem;
  object-fit: cover;
  border-radius: 0.25rem 0.25rem 0 0;
}

.card-title {
  font-size: 1.1em;
  text-wrap: balance;
  margin-top: -0.5em;
  margin-bottom: -0.5em;
}

.content {
  margin-top: -1em;
  max-width: 1000px;
}

.full-width {
  width: 100%;
}

.bottom {
  position: relative;
  bottom: 0;
}

@media (min-width: 640px) {
  .card {
    height: 100%;
  }

  .card-content {
    margin: 10px 0px -5px 0px;
  }

  .card-image {
    height: auto;
  }

  .card-image img {
    margin-top: 0px;
    object-fit: cover;
  }

  .bottom {
    position: absolute;
    bottom: 0;
  }
}

/* ABOUT */
@media (min-width: 640px) {
  .about-card {
    display: grid;
    grid-template-columns: minmax(0px, 215px) auto;
  }
}

.foundations figure {
  margin: 1.4em auto;
}

@media (min-width: 1600px) {
  .foundations {
    display: grid;
    grid-template-columns: auto 1fr;
  }

  .foundations figure {
    margin: 0px 40px 1.4em 0px;
  }
}

/* CAROUSEL */

.carousel-container ul li {
  padding-left: 0em;
}

.carousel-item {
  padding: 40px;
}

.carousel-container img {
  padding: 10px;
}

.carousel-container li {
  text-indent: 0em;
}

.carousel-container li:before {
  content: "";
  padding-right: 1em;
}

.react-multi-carousel-dot--active button {
  background-color: var(--blue-10) !important;
}

/* DIALOG */
dialog {
  display: none;
  z-index: 999;
}

@media (min-width: 768px) {
  dialog {
    display: inline-block;
    border: solid 3px var(--gray-50);
    position: fixed;
    top: 6vh;
  }

  dialog img {
    max-height: 80vh;
  }

  dialog figcaption {
    text-align: center;
  }

  .small {
    top: 12vh;
  }

  .small img {
    max-height: 68vh;
  }

  .products-popup {
    top: 20vh;
  }

  .products-popup img {
    margin: 0 40px;
  }

  .close-icon {
    content: "x";
    cursor: pointer;
    position: absolute;
    right: 28px;
    top: 28px;
    background: #ededed;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 0.9em;
    z-index: 999;
  }
}

.checkout-form {
  margin: 0px 40px;
}

/* CHECKOUT */
@media (min-width: 1024px) {
  .checkout-form {
    min-width: 492px;
    max-width: 800px;
  }
}

/* DIVIDER */

.product-page-divider {
  width: 100%;
  height: 1.8em;
  border-bottom: 2px solid #dbdbdb;
  text-align: center;
  position: relative;
  z-index: 0;
}

.product-page-divider-title {
  text-align: center;
  font-size: 1.3em;
  margin-top: 11px;
  background: #f9f9f9;
  z-index: 1;
  display: inline-block;
  padding: 0px 20px;
  font-family: Raleway;
  font-feature-settings: "lnum";
}

.test {
  color: #6fb2b7;
}

.membership-product-box {
  border: 3px solid #bdd8da;
  padding: 10px;
  background-color: #dee8e9;
}

.membership-product-underline {
  width: 100%;
  height: 1.5em;
  /* border-bottom: 2px solid #dee8e9; */
  text-align: center;
  position: relative;
  z-index: 0;
  margin-bottom: 0.5em;
  max-width: 150px;
}

.membership-product-underline-title {
  margin-bottom: 0.2em;
  font-weight: 900;
  text-align: center;
  color: rgb(48, 49, 61);
}

/* MODAL */
.enterprise-modal {
  top: 13vh;
  width: 100%;
}

.enterprise-image {
  max-width: 220px;
  max-height: 220px;
  margin: 0px 20px 20px 0px;
}

.enterprise-modal-input {
  height: 44.4px;
  padding: 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.4px;
  border: 2px solid var(--gray-47) !important;
  text-align: center;
  max-width: 100px;
  margin-bottom: 0px !important;
}

@media (min-width: 640px) {
  .enterprise-modal {
    width: 90.5%;
    height: 74vh;
  }
}

/* LANDING PAGE */
.landing-page {
  background: #dee8e9;
  padding: 20px;
}

.landing-page-title {
  z-index: 2;
  /* font-size: 1.2em; */
}

.landing-page .card-content {
  padding-top: 0;
  padding-bottom: 0;
}

@media (min-width: 768px) {
  .landing-page .card-content {
    padding: 0;
  }
}

.landing-page .product-title {
  margin-top: 0.8em;
  padding-left: 0px;
  margin-bottom: 0.5em;
}

.landing-page-img {
  /* margin: 0px 40px 20px 0px; */
}

#pricing-table {
  width: 280px;
  background: rgba(255, 255, 255, 0.4);
  height: 273.7;
  width: 120%;
  margin-bottom: 1em;
  overflow: hidden;
}

@media (min-width: 660px) {
  .landing-page-title {
    z-index: 2;
    font-size: 2rem;
  }

  #pricing-table {
    padding: 20px;
    width: auto;
  }
}

@media (min-width: 900px) {
  #pricing-table {
    margin-bottom: 0;
  }
}

@media (min-width: 1024px) {
  .landing-page-inner {
    max-width: 1331px;
    margin: 50px auto;
  }

  #pricing-table {
    width: auto;
    margin-bottom: 0em;
  }
}

.landing-page-divider {
  width: 100%;
  border-bottom: 2px solid var(--gray-40);
  z-index: 0;
  margin: 2em 0 2em 0;
  font-family: Raleway;
  font-feature-settings: "lnum";
}

.landing-page-close-icon {
  content: "x";
  color: var(--gray-10);
  cursor: pointer;
  position: absolute;
  right: 34px;
  top: 34px;
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 0.9em;
  z-index: 999;
}

/* EVENTS */
.event-description p {
  margin-bottom: 0.5em;
}

@media (min-width: 640px) {
  .events-page {
    margin-left: 16px;
  }
}

h4 {
  font-weight: 900;
  font-family: Raleway;
  font-size: 1.05em;
  font-feature-settings: "lnum";
}

.product-layout h4 {
  font-size: 1.15em;
}

.product-layout p {
  font-size: 1.05em;
}

.product-layout li {
  font-size: 1.05em;
}

/* ANIMATIONS */

@keyframes jump-shaking {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateY(-3px);
  }
  35% {
    transform: translateY(-3px) rotate(17deg);
  }
  55% {
    transform: translateY(-3px) rotate(-17deg);
  }
  65% {
    transform: translateY(-3px) rotate(17deg);
  }
  75% {
    transform: translateY(-3px) rotate(-17deg);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}

.jump-shake {
  animation: jump-shaking 0.83s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in-image {
  animation: fadeIn 5s;
}

/* TESTIMONIAL CARDS */

.custom-popover {
  position: relative;
  display: inline-block;
}

.popover-content {
  background-color: #fff;
  filter: drop-shadow(0 2px 10px rgba(0, 0, 0, 0.2));
  padding: 10px;
  border-radius: 4px;
  min-height: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-popover:after {
  content: "";
  content: "";
  position: absolute;
  left: 10%;
  border-width: 15px 15px 0px;
  border-style: solid;
  filter: drop-shadow(2px 7px 4px rgba(0, 0, 0, 0.1));

  border-color: #fff transparent;
  display: block;
  width: 0px;
}

.profile-wrapper {
  display: flex;
  align-items: center;
  margin-top: 25px;
  margin-left: min(15%, 67px);
}

.profile-pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transform: translateX(-48%);
}

.profile-content {
  justify-content: flex-start;
  flex-direction: column;
  transform: translateX(-5%);
}

.profile-name {
  font-size: 17px;
  /* font-weight: 700; */
}

.profile-detail {
  font-size: 15px;
  /* font-weight: 700; */
  /* color: var(--gray-10); */
}

.flex-item-testimonial {
  flex: 0 0 33.3%;
}

@media screen and (max-width: 768px) {
  .flex-item-testimonial {
    flex: 0 0 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .flex-item-testimonial {
    flex: 0 0 50%;
  }
}
